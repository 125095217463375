import omitBy from "lodash/omitBy";
import i18next from "i18next";

const setQueryString = (filters: Array<string | number>): string => {
  let queryString = "";
  Object.entries(filters).forEach(([key, value]: any) => {
    queryString += `&${key}=${value}`;
    return queryString;
  });
  return queryString;
};

const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  const isAM = hours < 12;
  const formattedHours = isAM ? hours : hours % 12;
  const period = isAM ? i18next.t('AM') : i18next.t('PM');

  return `${formattedHours}:${minutes} ${period}`;
}

const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

/**
 * @param dateString
 */
function getAccommodation(dateString) {
  const createdAt = new Date(dateString);
  const diffTime = Date.now() - createdAt.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

const removeEmptyPassword = (object) =>
  omitBy(object, (value, key) => key === "password" && value === "");

const removeEmptyProperties = (object) =>
  omitBy(
    object,
    (value) => value === "" || value === null || value === undefined
  );

/**
 * @param array
 * @param item
 */
function insertItem(array, item) {
  const newArray = array.slice();
  newArray.splice(0, 0, item);
  return newArray;
}

/**
 * @param array
 * @param updatedItem
 */
function updateObjectInArray(array, updatedItem) {
  return array.map((item) => {
    if (item.id !== updatedItem.id) {
      return item;
    }
    return updatedItem;
  });
}

const transformData = (data) => (data ? data.map((item) => item.id) : []);

const isArray = (a) => !!a && a.constructor === Array;
const isObject = (a) => !!a && a.constructor === Object;

/**
 * Return available slots per day
 *
 * @param {*} start
 * @param {*} end
 * @param {*} slotTime
 * @returns
 */
function getIndicatorPerDay(start, end, slotTime) {
  const startDate = new Date(`1/1/2022 ${start}`);
  const endDate = new Date(`1/1/2022 ${end}`);
  const diff = (endDate - startDate) / 60000;
  const availableSlots = Math.floor(diff / slotTime);
  return availableSlots > 0 ? availableSlots : 0;
}

function getBlobExt(blob) {
  console.log(blob);
  var type = blob.type;
  var ext = "";
  switch (type) {
    case "image/jpeg":
    case "image/jpg":
        ext = ".jpg";
        break;
    case "image/png":
        ext = ".png";
        break;
    case "image/gif":
        ext = ".gif";
        break;
    case "application/pdf":
        ext = ".pdf";
        break;
    default:
        ext = "";
        break;
  }
  return ext;
}


function getAgeByMonths(today,birth){
  return (
        (today.getMonth() - birth.getMonth() 
        + (today.getDate() - birth.getDate()) / 30) )
}
function calculateAge(birthDate){
  const today = new Date();
  const birth = new Date(birthDate);

  const yearDiff = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  const dayDiff = today.getDate() - birth.getDate();
  const hourDiff = today.getHours() - birth.getHours();
  const minuteDiff = today.getMinutes() - birth.getMinutes();
  const secondDiff = today.getSeconds() - birth.getSeconds();

  let calculatedAge = yearDiff;

  const hasBirthdayPassed =
    monthDiff < 0 ||
    (monthDiff === 0 &&
      (dayDiff < 0 ||
        (dayDiff === 0 &&
          (hourDiff < 0 ||
            (hourDiff === 0 && (minuteDiff < 0 || (minuteDiff === 0 && secondDiff < 0)))))));

  if (hasBirthdayPassed) {
    calculatedAge--;
  }

  let fractionalAge;
  let DateByMonth;
  if (calculatedAge === 0) {
    // Calculate age in months for ages less than 1 year
    if(getAgeByMonths(today,birth)>=0)
          DateByMonth = Math.round(getAgeByMonths(today,birth))
    else
    {
          fractionalAge--;
          DateByMonth=12+(Math.round(getAgeByMonths(today,birth)))
          ;
    }
    fractionalAge = DateByMonth +`${i18next.t(" Months")}`|| 0;
  } else {
    // Calculate age in years for ages 1 year or older
    if(getAgeByMonths(today,birth)>=0)
         DateByMonth = Math.round(getAgeByMonths(today,birth))
    else
    {
         fractionalAge--;
         DateByMonth=12+(Math.round(getAgeByMonths(today,birth)))
         ;
    }
    fractionalAge = calculatedAge +`${i18next.t(" Years and ")}` + DateByMonth +`${i18next.t(" Months")}`;
  }
 return fractionalAge;

};
function getColumnOptions(schema, selectedTable, selectedJoins,selectedGroup=null) {
  const table = schema.tables.find((table) => table.name === selectedTable);
  const selectedJoinTables = schema.tables
    .filter((table) => selectedJoins && selectedJoins.includes(table.name))
    .map((table) => table.name);
  const columns = schema.columns.filter((column) => {
    if (column.table === selectedTable || selectedJoinTables && selectedJoinTables.includes(column.table) || column.table === selectedGroup) {
      return true;
    }
  });
  return columns.map((column) => ({
    name: column.name,
    label: column.label,
    table: column.table,
    type: column.type,
    aggregate: column.aggregate,
    operator: column.operator,
  }));

}
function timeDifference(elapsed) {

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var days = 0; var hours = 0; var minutes = 0, months = 0;

  if (elapsed > msPerMonth) {
    months = parseInt(elapsed/msPerMonth);
    elapsed -= (months * msPerMonth);
  }
  if (elapsed > msPerDay) {
    days = parseInt(elapsed/msPerDay);
    elapsed -= (days * msPerDay);
  }

  if (elapsed > msPerHour) {
    hours = parseInt(elapsed/msPerHour);
    elapsed -= (hours * msPerHour);
  }

  if (elapsed > msPerMinute) {
    minutes = parseInt(elapsed/msPerMinute);
    elapsed -= (minutes * msPerMinute);
  }
  return `${months? months + ' Months': ''} ${days? days + ' Days': ''} ${hours? hours + ' Hours': ''} ${minutes? minutes + ' Minutes': ''}`;
  /*else if (elapsed < msPerYear) {
    return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';
  }

  else {
    return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';
  }*/
}

function hierarchyToReactFlow(hierarchy, createNodeLabel, width, height, startX = 300, startY = 50, id_column = 'hospital_id', concat = "") {
  const nodeWrapperStyle = {
    boxShadow: "none", // Removes the shadow
    backgroundColor: "transparent", // Makes the background transparent
    border: "none", // Removes the border
  };
  const nodes = [];
  const edges = [];
  const buildNodes = (node, parentId, x, y) => {
    const n = {
      id: node[id_column] + concat,
      data: { label: createNodeLabel(node) },
      position: { x: x, y: y },
      style: nodeWrapperStyle,
    };
    if (parentId === false) {
      n.type = "input";
    }
    nodes.push(n);
    if (parentId !== false) {
      edges.push({ id: `e${parentId}-${node[id_column]}${concat}`, source: parentId + concat, target: node[id_column] + concat, animated: true });
    }
    if (node.hospitals) {
      const startXPosition = x - ((node.hospitals.length - 1) * width / 2);
      node.hospitals.forEach((h, index) => {
        buildNodes(h, node[id_column], startXPosition + (index * width), y + height);
      });
    }
  };
  buildNodes(hierarchy, false, startX, startY);
  return {nodes, edges};
}

export {
  getBlobExt,
  getAge,
  formatTime,
  getAccommodation,
  removeEmptyPassword,
  insertItem,
  updateObjectInArray,
  removeEmptyProperties,
  transformData,
  isArray,
  isObject,
  getIndicatorPerDay,
  calculateAge,
  getColumnOptions,
  timeDifference,
  hierarchyToReactFlow
};

export default setQueryString;
